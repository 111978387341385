import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Box, Typography, IconButton, Tooltip } from '@mui/material';
import { LogoutOutlined } from '@ant-design/icons';

const Profile = () => {
  const { logout, user } = useAuth0();

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2,marginLeft:'10px' }}>
      <Box style={{textAlign:'right'}}>
        <Typography style={{color:'#383838',fontSize:'15px',fontWeight:'600',marginBottom:'0px',paddingBottom:'0px'}}>{user.name}</Typography>
        <Typography style={{color:'#383838',fontSize:'11px',marginTop:'0px'}}>{user.email}</Typography>
      </Box>
      <Avatar alt="Avatar" src={user.picture} style={{height:'30px',width:'30px'}}/>
      <Tooltip title="Logout">
        <IconButton onClick={handleLogout}>
          <LogoutOutlined style={{color:'#161616'}}/>
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default Profile;
