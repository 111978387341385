// material-ui
import { useTheme } from '@mui/material/styles';
import logo_mini from 'assets/logo_mini.png';

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return (
    <img src={logo_mini} alt="Instalily" width="30" />
  );
};

export default LogoIcon;
