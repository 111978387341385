// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { MessageOutlined, FolderOutlined, DatabaseOutlined, UploadOutlined, AreaChartOutlined, RightOutlined, UserAddOutlined } from '@ant-design/icons';

// icons
const icons = { MessageOutlined, FolderOutlined, DatabaseOutlined, UploadOutlined, AreaChartOutlined, RightOutlined, UserAddOutlined };

const pages = {
    id: 'all-pages',
    type: 'group',
    children: [
        {
            id: 'chathistory',
            title: <FormattedMessage id="Conversation History" />,
            type: 'item',
            url: '/chathistory',
            icon: icons.MessageOutlined
        },
        {
            id: 'useraccess',
            title: <FormattedMessage id="User Access" />,
            type: 'item',
            url: '/useraccess',
            icon: icons.UserAddOutlined
        },
        {
            id: 'analytics',
            title: <FormattedMessage id="Analytics" />,
            type: 'item',
            url: '/analytics',
            icon: icons.AreaChartOutlined
        },
        {
            id: 'upload',
            title: <FormattedMessage id="Upload Data" />,
            type: 'item',
            url: '/upload',
            icon: icons.UploadOutlined,
            disabled: true
        }
    ]
  };

export default pages;