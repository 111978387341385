import { lazy } from 'react';

import AuthGuard from 'utils/route-guard/AuthGuard';
import { Navigate } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import DashboardLayout from 'layout/Dashboard';
import PagesLayout from 'layout/Pages';
import SimpleLayout from 'layout/Simple';
import { SimpleLayoutType } from 'config';
import LayoutComponent from 'layout/Custom';

// render
const ChatLogsPage = Loadable(lazy(() => import('pages/chatlogs/chatlogs')));
const UploadPage = Loadable(lazy(() => import('pages/upload/upload')));
const AnalyticsPage = Loadable(lazy(() => import('pages/analytics/analytics')));
const Analytics2Page = Loadable(lazy(() => import('pages/analytics2/analytics2')));
const UatPage = Loadable(lazy(() => import('pages/uat/uat')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout/>
        </AuthGuard>
      ),
      children: [
        {
          path: '/',
          element: <Navigate to="chathistory" />
        },
        {
          path: 'chathistory',
          element: <ChatLogsPage />,
        },
        {
          path: 'useraccess',
          element: <UatPage />,
        },
        {
          path: 'upload',
          element: <UploadPage />,
        },
        {
          path: 'analytics',
          element: <AnalyticsPage />,
        },
        {
          path: 'analytics2',
          element: <Analytics2Page />,
        },
      ]
    },
  ]
};

export default MainRoutes;
