import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

// project-import
import { ThemeMode } from 'config';
import logo from 'assets/logo.png';
import srs from 'assets/srs-logo.png';

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse }) => {
  const theme = useTheme();
  return (
    <>
    <img src={logo} alt="Instalily" width="200" />
    {/* <img src={srs} alt="srs" width="200" /> */}
    </>
  );
};

export default LogoMain;
