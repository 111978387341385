
// auth provider
import { useAuth0 } from '@auth0/auth0-react';

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = () => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated;
};

export default useAuth;
